<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <h3>{{ cardTitle }}</h3>
      </v-card-title>
      <v-card-text>
        <p class="text">
          {{ $t("AUTH.VALIDATION_2FA.text") }}
        </p>
        <div v-if="user && !user.two_factor_auth_secret" class="mt-2 text">
          <v-btn
            @click="generate"
            class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            {{ $t("AUTH.VALIDATION_2FA.button") }}
          </v-btn>
        </div>
        <div v-else-if="user && !user.two_factor_auth_enable" class="mt-2 text">
          <p class="topbar-item">
            1. {{ $t("AUTH.VALIDATION_2FA.text1") }} <code>{{ secret }}</code>
          </p>
          <br />
          <img :src="google2fa_url" alt="" />
          <v-form v-model="formValid" ref="form">
            <v-row>
              <v-col cols="12">
                2. {{ $t("AUTH.VALIDATION_2FA.text_qr") }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.verify_code"
                  :label="$t('AUTH.VALIDATION_2FA.placeholder')"
                  :rules="verifyCodeRules"
                  id="verifyCode"
                  :error-messages="messages['verify_code']"
                  @keyup="messages['verify_code'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-btn
                  @click="enable2fa"
                  class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
                  >{{ $t("AUTH.VALIDATION_2FA.button_qr") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div v-else-if="user && user.two_factor_auth_enable" class="text">
          <p style="color: green">
            <!-- 2FA is currently <strong>enabled</strong> on your account. -->
            {{ $t("AUTH.VALIDATION_2FA.majd_zolddel") }}
          </p>
          <p>
            {{ $t("AUTH.VALIDATION_2FA.text_qr2") }}
          </p>

          <v-form v-model="formValid" ref="form">
            <!--<v-row>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.password"
                  :label="$t('FORMS.synergy_password')"
                  :rules="passwordRules"
                  id="password"
                  type="password"
                  :error-messages="messages['password']"
                  @keyup="messages['password'] = ''"
                ></v-text-field>
              </v-col>
            </v-row>-->
            <v-row>
              <v-col cols="12" sm="3" md="3">
                <v-btn
                  @click="disable2fa"
                  class="btn btn-secondary font-weight-bold px-9 py-4 my-3 font-size-3"
                  >{{ $t("AUTH.VALIDATION_2FA.button2") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
export const PERMISSION_TO = "products.product.";
import ApiService from "@/core/services/api.service";

export const initialFormData = () => ({
  verify_code: null,
  //password: null,
});

export default {
  name: "TwoFactorAuthSettings",
  components: {},
  data() {
    return {
      loader: false,
      user: null,
      formValid: false,
      formModel: Object.assign({}, initialFormData()),
      secret: null,
      google2fa_url:
        "https://is3-ssl.mzstatic.com/image/thumb/Purple116/v4/ec/1a/b0/ec1ab0c1-cdcc-d0b8-88cd-fcf218cb834f/Production-0-1x_U007emarketing-0-0-0-7-0-0-85-220.png/1200x630wa.png",
      cardTitle: this.$t("AUTH.VALIDATION_2FA.title"),
      routePath: "/2FA-settings/",
      errorReports: null,
      messages: {},
      verifyCodeRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
    };
  },

  computed: {},

  methods: {
    getSettings() {
      this.loader = true;
      ApiService.get("two-factor-auth/show")
        .then((response) => {
          // console.log("response.data ", response.data);
          this.user = response.data.user;
          this.secret = response.data.secret;
          this.google2fa_url = response.data.google2fa_url;
        })
        .catch((errors) => {
          console.log(errors.response);
        })
        .finally(() => {
          this.loader = false;
          this.formValid = false;
          this.messages = {};
          this.formModel = Object.assign({}, initialFormData());
        });
    },
    generate() {
      this.loader = true;
      ApiService.post("two-factor-auth/generate", [])
        .then(() => {
          this.getSettings();
        })
        .catch((errors) => {
          console.log(errors.response);
          this.loader = false;
        })
        .finally(() => {
          this.loader = false;
        });
    },
    enable2fa() {
      if (this.formValid) {
        let model = Object.assign({}, this.formModel);
        this.loader = true;
        ApiService.post("two-factor-auth/enable", model)
          .then(() => {
            this.getSettings();
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.messages = error.response.data.errors;
            } else {
              console.log(error);
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    disable2fa() {
      let confirm = window.confirm('Valóban kikapcsolja a kétfaktoros autentikációt?');  
      //if (this.formValid) {
      if(confirm) {
        //let model = Object.assign({}, this.formModel);
        this.loader = true;
        //ApiService.post("two-factor-auth/disable", model)
        ApiService.post("two-factor-auth/disable")
          .then(() => {
            this.getSettings();
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.messages = error.response.data.errors;
            } else {
              console.log(error);
            }
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
  },

  mounted() {
    this.getSettings();
  },
};
</script>

<style scoped>
.text {
  font-size: 13px !important;
  font-weight: 400;
}
</style>
